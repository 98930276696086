.chart2onlyprint{
  width: 100% !important;
}

@media print{
  body{
    background-color: #fff;
  }

  #sidebar,
  .sidebar-bg,
  .sidebar-mobile-dismiss,
  #sidebar-right,
  #header,
  .panel-heading{
    display: none !important;
  }

  .content{
    margin-left: 0px !important;
  }

  .page-header-fixed{
    padding-top: 0px !important;
  }

  .chart2onlyprint{
    width: 90% !important;
  }

}

  @page {
    size: Letter;
    margin: 0;
}



.table-resume tr td{
  text-align: justify;
}


