.permissions .widget-todolist .widget-todolist-item {
    display: inline-flex !important;
}

.permissions .widget-todolist-content{
    border: none !important;
}

.permissions .widget-todolist-input > .form-check > .form-check-input{
    cursor: pointer;
}