.mailbox .list-email > li.list-group-item .email-info > div {
    margin-top: -15px;
    margin-bottom: -15px;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
}

.fa-20px{
    font-size: 20px;
}

.color-unimportant{
    color: #ccc;
    cursor: pointer;
}

.color-important{
    color: #dcda41;
    cursor: pointer;
}

.mailbox .nav-inbox > li.active > span {
    background-color: #ced4da;
    background-color: var(--app-component-border-color);
    color: #20252a;
    color: var(--app-component-color);
}

.mailbox .nav-inbox > li > span {
    text-decoration: none;
    border-radius: 0;
    padding: 0.46875rem 0.9375rem;
    color: #20252a;
    color: var(--app-component-color);
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
}

mailbox .nav-inbox > li > span i {
    width: 24px;
}