.app-content{
    padding: 0px 20px !important;
}

.barfixed{
    /* margin-top: -12px;
    padding-top: 15px; */
    z-index: 999;
    background: #d9e0e7;
}

.result-panel{
    padding-top: 60px !important;
    width: 100%;
}

.result-panel.open{
    padding-top: 240px !important;
}

.result-list>li .result-image{
    width: 160px !important;
}

.export-pdf{
    color: red;
    font-size: 18px;
}

.export-xls{
    color: green;
    font-size: 18px;
}

.specialfield{
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.specialfield label{
    top: 7px;
}


.fullwidth{
    width: 100%;
}

.fas.fa-spinner.fa-spin{
    font-size: 18px;
}

h4.panel-title > .btn-group{
    float: left;
}

.with-img img.zoom{
    transition: transform .2s;
    cursor: pointer;
}

.with-img img.zoom:hover{
    transform: scale(4);
    cursor: pointer;
}

div.DateRangePicker{
    position: absolute;
    left: 10px;
    top:35px;
    background: #fff;
    padding-bottom: 20px;
    border: 1px solid #ccc;
}

.z-index-10 {
    z-index: 10;
}