/* @import "../../../assets/css/pages/login-register-lock.css"; */
.checkbox.checkbox-css{
    margin-top: 0px;
}

.dropdown-content .select-panel label.select-item{
    margin-bottom: 0px !important;
}


.login.login-v2 .login-header .brand{
    font-size: 20px !important;
    text-align: center;
}

.login.login-v2 .login-container{
    background-color: #00000096;
}

.login-with-news-feed .login-header .brand{
    font-size: 20px !important;
    text-align: center;
}