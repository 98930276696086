.checkbox.checkbox-css{
    margin-top: -30px;
}

.widget-todolist-body.templateemails{
   height: 50vh;
   overflow: auto;
}

.toolbarClassName{
    width: 85%;
}

.mailboxTemplate .mailbox-sidebar{
    width: 400px !important;
    flex: 0 0 400px !important;
}

.mailboxTemplate .mailbox-content{
    max-width: calc(100% - 400px) !important;
}

.mailbox .list-email > li.list-group-item .email-info > a {
    margin-top: -15px;
    margin-bottom: -15px;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
}