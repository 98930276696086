/* .sendMailPopup.modal-dialog {
    width: 80vw;
    max-width: 80vw;
    height: 80vh;
    max-height: 80vh;
    margin: 1.75rem auto;
}

.sendMailPopup.modal-dialog .modal-body{
    height: 70vh;
} */

.manage-fases{
    padding: 0 !important;
}


.manage-fases .draggable{
    background: #fff;
    box-shadow: 0 1px 0 rgba(9,30,66,.25);
    cursor: pointer;
    display: block;
    margin-bottom: 10px;
    position: relative;
    color: #172b4d;
    cursor: grab;
    min-height: 70px;
}

.manage-fases .draggable:hover{
    background-color: #fff8f8;
    border-bottom-color: rgba(9,30,66,.25);
}

.manage-fases .widget-list{
    min-height: 60vh;
}

.manage-fases .widget-list-input{
    padding: 0.61875rem 0.9375rem;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.manage-fases .widget-list-input .form-check .form-check-input,
.manage-fases .widget-list-action{
    cursor: pointer;
}

.manage-fases .widget-list-media{
    padding-left: 0px !important;
}

.manage-fases .widget-list-content,
.manage-fases .widget-list-action{
    border: none !important;
}

.manage-fases .widget-list-desc,
.manage-fases .widget-list-title{
    cursor: default;
}

.manage-fases .panel-heading{
    border-radius: 0 !important;
}