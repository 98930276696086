@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700";
@import "https://cdnjs.cloudflare.com/ajax/libs/nvd3/1.8.1/nv.d3.min.css";
@import "https://unpkg.com/ionicons@4.2.2/dist/css/ionicons.min.css";

.height-30{
    height: 30px;
}

.height-50{
    height: 50px;
}

.app-content{
padding: 10px 20px !important;
}

.pull-right{
    float: right !important;
}

.registered-users-list > li{
    width: 33% !important;
}

.widget-stats .stats-link a, .widget.widget-stats .stats-link a{
    text-align: left !important;
}

.container-drag .col-md-1{
    border: 1px dashed #ffc;
}

div.draggable{
    margin-bottom: 5px;
}

.panel{
   margin-bottom: 10px !important; 
}

.page-with-two-sidebar.page-sidebar-fixed .sidebar.sidebar-right{
    width: 500px !important;
    background: #fff !important;
}

.page-with-two-sidebar.page-right-sidebar-collapsed .sidebar-right.sidebar, .page-with-two-sidebar.page-right-sidebar-collapsed .sidebar-right.sidebar-bg{
    right: -500px !important;
}

.page-with-two-sidebar.page-right-sidebar-collapsed .content {
    margin-right: 0 !important;
}

@media (min-width: 768px)
{
.page-with-two-sidebar .content {
    margin-right: 500px !important;
}
}

.app-sidebar-end-toggled .app-content {
    margin-right: 420px !important;
}

.applicantComments {
    height: calc(100vh - 250px);
}

.selectCustomClass > *,
.css-b62m3t-container > *{
    color: #20252a !important;
}


.imageRotateSpin {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    margin:-60px 0 0 -60px;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.app-sidebar .menu .menu-item .menu-link:hover,
.app-sidebar .menu .menu-item .menu-link:focus{
    cursor: pointer;
}

.card {
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 19%);
}

.p-t-50{
    padding-top: 50px
}

.font-size-15{
    font-size: 15px;
}

.border-content{
    border-top: 3px solid #ccc;
}