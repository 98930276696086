.col-form-label{
    word-wrap: break-word;
}

.checkbox-selected{
    color: #348fe2;
    border-radius: 5px;
    height: 18px;
    width: 18px;
    font-size: 18px;
}

.checkbox-unselected{
    color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 18px;
    width: 18px;
}

.forceMargin {
    margin-bottom: 120px !important;
}

/* .form-aplicant{
    float: left;
}

.profile-content .table.table-profile tbody>tr td.field{
    vertical-align: middle;
}

.css-2b097c-container{
    width: 100%;
}

.content{
    padding-top: 0px !important;
}

.todolist>li.active>a .todolist-title {
    text-decoration: none !important;
    color: #0d7dec;
    font-weight: bold;
}

.control-label.field{
    font-weight: 600;
    color: #2d353c;
}

.form-flex{
    display: flex;
}

.col-form-label.txt-bold{
    font-weight: bold;
    text-align: left;
    padding-left: 0px;
}

.css-2b097c-container{
    max-width: 100%;
}

.nav-pills .nav-item .nav-link{
    cursor: pointer;
}

.pull-right.btn-group button{
    border-radius: 6px !important;
}

.checkbox.checkbox-css label:before{
    border: 1px solid #00b7b7;
}

.media-list.dropdown-menu-right{
    max-height: 300px;
    overflow: auto;
}

.specialfield label.control-label:first-of-type{
    padding-left: 2px;
}

@media screen and (max-width:375px) {
    .result-panel{
        padding-top: 0px !important;
    }

    .position-fixed.barfixed{
        position: initial !important;
    }
} */