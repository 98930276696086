.forceFullWidth{
    max-width: 100% !important;
}

.mailboxv2 .mailbox-sidebar{
    width: 350px !important;
    flex: 0 0 350px !important;
}

.mailboxv2 .mailbox-content{
    max-width: 100% !important;
}

.mailboxv2.mailbox .mailbox-content .mailbox-content-body .mailbox-to .control-label{
    width: 120px;
}

.mailboxv1 .mailbox-content{
    max-width: 100% !important;
}

.documents .widget-todolist .widget-todolist-item {
    display: inline-flex !important;
}

.documents .widget-todolist-content{
    border: none !important;
}

.react-tags__selected-tag:after {
    content: '✕' !important;
}

.react-tags__suggestions {
    z-index: 9 !important;
}
.react-tags__suggestions ul li {
    padding: 10px 10px !important;
}