.root-react-component-countdown-timer.inline.left 
{
    direction: rtl;
    display: flex;
}

.displayedTime .split {
  background-color: #fff;
  color: #20252a;
}

.root-react-component-countdown-timer .left {
    direction: rtl !important;
  }
  .root-react-component-countdown-timer  .inline.left {
    display: inline-flex;
    direction: rtl !important;
  }
  .root-react-component-countdown-timer  .inline.responsive {
    width: auto;
    display: flex;
  }
  .root-react-component-countdown-timer  .countBox.responsive {
    width: auto;
  }
  .root-react-component-countdown-timer  .countBox {
    display: flex;
    width: 215px;
    font-size: 25px !important;
  }
  .root-react-component-countdown-timer  .countBox .countBoxItem {
    flex: 1;
  }
  .root-react-component-countdown-timer  .countBox .countBoxItem .label {
    font-size: 12px;
    text-align: center;
    display: none;
  }
  .root-react-component-countdown-timer  .countBox .countBoxItem .count {
    text-align: center;
  }
  .root-react-component-countdown-timer  .inline {
    display: flex;
    width: 215px;
  }
  .root-react-component-countdown-timer  .inline .countBoxItem {
    flex: 1;
  }
  .root-react-component-countdown-timer  .inline .countBoxItem .label {
    font-size: 12px;
    text-align: center;
    display: none;
  }
  .root-react-component-countdown-timer  .inline .countBoxItem .count {
    text-align: center;
  }
  .root-react-component-countdown-timer  .small {
    width: 125px;
    font-size: 14px;
  }
  .root-react-component-countdown-timer  .small .label {
    font-size: 10px;
    display: none;
  }

  .root-react-component-countdown-timer  .small .count {
    font-size: 14px;
  }
  .root-react-component-countdown-timer  .small.responsive {
    display: flex !important;
    width: auto !important;
  }
  .root-react-component-countdown-timer  .small.responsive .count {
    flex: 1;
    text-align: center;
  }
  .root-react-component-countdown-timer  .small.border {
    width: 145px;
  }
  .root-react-component-countdown-timer  .small.border .count {
    line-height: 32px;
    height: 30px;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
    margin: 2px;
    padding: 0 5px;
    flex: 1;
    text-align: center;
  }
  .root-react-component-countdown-timer  .inline.small .count {
    display: inline-block;
  }
  .root-react-component-countdown-timer  .inline.small.right {
    display: inline-block;
    direction: ltr;
  }
  .root-react-component-countdown-timer  .inline.small.left {
    direction: rtl;
  }
  .root-react-component-countdown-timer  .countBox.small.right {
    display: inline-flex;
    direction: rtl;
  }
  .root-react-component-countdown-timer  .countBox.small.left {
    direction: ltr;
  }
  .root-react-component-countdown-timer  .middle {
    width: 155px;
    font-size: 18px;
  }
  .root-react-component-countdown-timer  .middle .label {
    font-size: 12px;
    display: none;
  }
  .root-react-component-countdown-timer  .middle .count {
    font-size: 18px;
  }
  .root-react-component-countdown-timer  .middle.right {
    display: inline-block;
    direction: ltr;
  }
  .root-react-component-countdown-timer  .middle.responsive {
    display: flex !important;
    width: auto !important;
  }
  .root-react-component-countdown-timer  .middle.responsive .count {
    flex: 1;
    text-align: center;
  }
  .root-react-component-countdown-timer  .middle.border {
    width: 160px;
  }
  .root-react-component-countdown-timer  .middle.border .count {
    line-height: 32px;
    height: 30px;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
    margin: 2px;
    padding: 0 5px;
    flex: 1;
    text-align: center;
  }
  .root-react-component-countdown-timer  .inline.middle .count {
    display: inline-block;
  }
  .root-react-component-countdown-timer  .inline.middle.right {
    display: inline-block;
    direction: ltr;
  }
  .root-react-component-countdown-timer  .inline.middle.left {
    direction: rtl;
  }

  .root-react-component-countdown-timer  .large {
    width: 200px;
    font-size: 22px;
  }
  .root-react-component-countdown-timer  .large .label {
    font-size: 12px;
    display: none;
  }
  .root-react-component-countdown-timer  .large .count {
    font-size: 22px;
  }
  .root-react-component-countdown-timer  .large.right {
    direction: ltr;
  }
  .root-react-component-countdown-timer  .large.responsive {
    display: flex !important;
    width: auto !important;
  }
  .root-react-component-countdown-timer  .large.responsive .count {
    flex: 1;
    text-align: center;
  }
  .root-react-component-countdown-timer  .large.border {
    width: 185px;
  }
  .root-react-component-countdown-timer  .large.border .count {
    line-height: 32px;
    height: 30px;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
    margin: 2px;
    padding: 0 5px;
    flex: 1;
    text-align: center;
  }
  .root-react-component-countdown-timer  .inline.large .count {
    display: inline-block;
  }
  .root-react-component-countdown-timer  .inline.large.right {
    display: inline-block;
  }
  .root-react-component-countdown-timer  .countBox.large.right {
    display: inline-flex;
  }
  .root-react-component-countdown-timer  .countBox.large.left {
    direction: ltr;
  }
  .root-react-component-countdown-timer  .big .count {
    font-size: 26px;
  }

  .root-react-component-countdown-timer  .big.right {
    direction: ltr;
  }
  .root-react-component-countdown-timer  .big.responsive {
    display: flex !important;
    width: auto !important;
  }
  .root-react-component-countdown-timer  .big.responsive .count {
    flex: 1;
    text-align: center;
  }
  .root-react-component-countdown-timer  .big.border {
    width: 204px;
  }
  .root-react-component-countdown-timer  .big.border .count {
    line-height: 32px;
    height: 30px;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
    margin: 2px;
    padding: 0 5px;
    flex: 1;
    text-align: center;
  }

  .root-react-component-countdown-timer  .inline.big .count {
    display: inline-block;
  }
  .root-react-component-countdown-timer  .inline.big.right {
    display: inline-block;
  }
  .root-react-component-countdown-timer  .inline.big.left {
    direction: rtl;
  }

  .root-react-component-countdown-timer  .countBox.big.right {
    display: inline-flex;
  }
  .root-react-component-countdown-timer  .countBox.big.left {
    direction: ltr;
  }


/* .radio.radio-css {
  line-height: 25px !important;
  padding: 20px !important;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
} */

.termantest .form-check:hover, 
.termantest .form-check.selected {
  background-color:  #348fe2;
  color: #fff;
}

.disctest .form-check {
  display: flex;
  align-items: center;
  justify-content: center;
}

.disctest .form-check:hover,
.disctest .form-check.selected{
  background-color:  #f59c1a;
  color: #fff;
}

.termantest .form-check-input,
.disctest .form-check-input{
  font-size: 15px;
  cursor: pointer;
  margin-left: 0px !important;
}

.termantest .form-check-input:checked,
.disctest .form-check-input:checked{
  background-color: #20252a;
  border-color: #20252a;
}

.termantest .form-check-label,
.disctest .form-check-label{
  font-size: 20px;
  cursor: pointer;
  padding-left: 10px;
}
/* 
.radio.radio-css label {
  font-size: 20px !important;
  padding-left: 35px !important;
  width: 100%;
  cursor: pointer;
}

.radio.radio-css.selected label:before{
  background: #2d353c !important;
  border: 1px solid #2d353c !important;
}

.radio.radio-css.selected label:after{
  background: #2d353c !important;
}

.radio.radio-css label:before {
  width: 25px !important;
  height: 25px !important;
  background: #ffffff !important;
  border: 1px solid #ccc !important;
}
 
.radio.radio-css input:checked+label:after {
    width: 15px !important;
    height: 15px !important;
    border-radius: 10px !important;
} */

/* .radio.radio-css input:checked+label:before{
    border-color: initial !important;
} */

.bold{
  font-weight: 800;
}

.p-20{
  padding: 20px !important;
} 

.fs-20{
  font-size: 20px;
}

.questionTitle{
  font-size: 1.875rem;
}

.questionInstruction{
  font-size: 1.5rem;
}

@media only screen and (max-width: 600px) {
  .specialtest h1
  {
    font-size: 16px;
  }

  .radio.radio-css 
  {
    padding: 10px !important;
  }
}
