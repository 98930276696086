.nav-link.completed {
    background-color: var(--app-component-disabled-bg) !important;
    color: rgba(var(--app-component-color-rgb), 0.5) !important;
    cursor: pointer;
}
.nav-link.active {
    background-color: var(--app-component-active-bg) !important;
    color: var(--app-component-active-color) !important;
}

.labelTitle {
    color: #348fe2;
}

.border-section-primary{
    border: 1px solid #348fe2;
}

.border-section{
    border: 1px solid #348fe2;
}

.border-bottom-separate {
    border-bottom: 1px solid rgba(var(--app-component-color-rgb), 0.1);
}

.table > :not(caption) > * > *{
    border: 1px solid #dee2e6;
}