.overrideSidebarChart .widget-chart-sidebar{
    width: 100% !important;
}

.overrideSidebarChart .widget-chart .widget-chart-sidebar .chart-legend > li span,
.overrideSidebarChart .widget-chart .widget-chart-sidebar .chart-number small, .widget-chart .widget-chart-sidebar .chart-number .small{
    color: #fff !important;
}

.coverimage{
    background-image: url(https://media.istockphoto.com/photos/abstract-blurred-blue-background-with-double-exposure-of-bokeh-circle-picture-id1153938533?k=20&m=1153938533&s=170667a&w=0&h=mJhI8cuBdlaG8SiC8WlpG6rl-O5aSGaNdIQl5s3mN1I=);
    background-repeat: no-repeat;
    background-size: cover;
}

.app-footer{
    color: #000 !important;
}