/* #sidebar-right .panel .panel-footer{
    position: fixed;
    bottom: 0px;
    width: 100%;
}

.messagedatetime{
    position: absolute;
    right: 0;
} */

.app-sidebar-end{
    width: 420px !important;
}